/* CRM - CARDS */
.crm .card{	
	& .ant-card-body{
		padding-top: 12px;
	}

	&-header{
		margin-bottom: 12px !important;
	}

	&-title{
		font-size: 24px;
		color: #A0AEC0;
	}
}

/* CRM - ULTIMOS CLIENTES */
.crm .cliente{
	padding: 10px 0px;

	& .ant-card-body{
		padding: 0px;
	}

	&-avatar {
		height: 60px;
		width: 60px;

		& .ant-avatar-string{
			transform: scale(1) translateX(-50%) translateY(48%) !important;
		}
	}

	&-nombre{
		font-size: 16px;
	}

	&-subrubro{
		color: #858997;
	}

	&-fuente{
		font-size: 16px;
		color: #00FF19;
	}
}


/*CSS PARA MODIFICAR LOS ELEMENTOS DEL MODAL DETALLE CLIENTE*/
.modal-detalle{
	top: 50px !important;
}

.modal-detalle .ant-modal-content{
	border-radius: 25px !important;
}
.modal-detalle .col-divider{
	border-left: solid 1px #D12B00;
}

.modal-detalle .modal-col-chat{
	border-left: solid 1px red;
}

.modal-detalle .ant-modal-body{
	padding: 0px !important;
}

.modal-detalle .modal-col-content{
	padding: 1.5rem !important;
}

.modal-detalle .modal-col-content .tag-estatus{
	min-height: 28px;
	border-radius: 10px;
	line-height: 28px;
	min-width: 114px;
	text-align: center;
	background: rgba(107, 16, 255, 0.54);
	border-color: rgba(107, 16, 255, 0.54);
}

.tag-estatus.cancelado,
.modal-detalle .modal-col-content .tag-estatus.cancelado{
	color: white;
	background: #ff4d4f;
	border-color: #ff4d4f;
}
.tag-estatus.registrado,
.modal-detalle .modal-col-content .tag-estatus.registrado{
	color: #333333;
	background: #FFE608;
	border-color: #FFE608;
}
.tag-estatus.contactado,
.modal-detalle .modal-col-content .tag-estatus.contactado{
	color: white;
	background: #5465ff;
	border-color: #5465ff;
}
.tag-estatus.compro,
.modal-detalle .modal-col-content .tag-estatus.compro{
	color: white;
	background: #f2802e;
	border-color: #f2802e;
}
.tag-estatus.pagado,
.modal-detalle .modal-col-content .tag-estatus.pagado{
	color: white;
	background: #00cd98;
	border-color: #00cd98;
}


.modal-detalle .modal-col-content .text-name{
	font-size: 20px;
}

.modal-detalle .modal-col-content .text-title{
	font-weight: 500;
	font-size: 20px;
	line-height: 22px;
	text-align: center;
	color: #D12B00;
	margin: 0.5rem;
}

.modal-detalle .modal-col-content .ant-divider{
	background: #D12B00 !important;
	height: 2px;
}

.modal-detalle .modal-col-content .text-description{
	color: #585858 !important;
	font-weight: 500;
}


.modal-detalle .modal-col-content label{
	color: #696969;
}

.modal-detalle .modal-col-content .ant-form-item{
	margin-top: 4px;
	margin-bottom: 4px;
}

.modal-detalle{
	.tabs{
		&-buttons{
			background-color: #D12B00;
    		border-radius: 20px 20px 0 0;
			width: 100%;
    		padding: 1em;
		}

		.btn-selected{
			background: white;
			border-color: white;
			color: #D12B00;

			.anticon{
				color: #D12B00;
			}
		}

		.btn-link-selected{
			color: white;
		}

		.ant-tabs-content-holder{
			border-left: 1px solid #D12B00;
		}
	}

	.emails{

		.list-items {
			background:
				/* Shadow TOP */
				linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0)) center top,
				/* Shadow BOTTOM */
				linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) center bottom;
	
			background-repeat: no-repeat;
			background-size: 100% 5px, 100% 5px;
			background-attachment: scroll, scroll;
		}

		&-form{
			border-top: 1px solid #D12B00;
			padding: 1em 1em 0;
		}
	}
}



/*CSS PARA LA PARTE DEL CHAT DEL MODAL*/
.chat-content .mensajes-content{
	max-height: 700px;
	min-height: 700px;
	overflow: hidden;
	overflow-y: auto;
	padding-right: 1rem;
}

.chat-content .mensajes-content .mensaje-card{
	border: solid 1px #D12B00;
	margin: 4px;
	border-radius: 18px;
}

.chat-content .mensajes-content .mensaje-card .ant-card-body{
	padding: 12px;
	min-height: 100px;
}

.chat-content .mensajes-content .mensaje-card .mensaje-text{
	color: #585858 !important;
	font-size: 12px;
	font-weight: 400;
}

.chat-content .text-update-title{
	background: #D12B00;
	border-radius: 20px 20px 0 0;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: 400;
	font-size: 20px;
	margin: 0;
}

.chat-content .chat-content-opciones{
    padding: 10px 20px 20px;
    border-top: solid 1px #D12B00;

}

.chat-content .chat-content-opciones .btn-attachment{
	padding-top: 6px !important;
	width: 60px;
	cursor: pointer;
	color: red;
}

.chat-content .chat-content-opciones .btn-attachment:hover *{
	color: #D12B00;
}


.chat-content .chat-content-opciones .btn-send{
	background: #D12B00;
	border-color: #D12B00;
	color: white;
	letter-spacing: 0.1em;
	width: 160px;
}

.filesList {
    padding: 1.7em 1em 1em 1em;
    margin-top: 0.7em;
    border-top: 1px solid #e6e6e6;

}

.filesList .file-container {
    margin-left: 1em;
    display: inline-block;
}

.filesList .file {
    text-align: center;
    max-width: 230px;
    /*height: 100px;*/
    border: 1px solid #dedede;
    border-radius: 10px;
    position: relative;
}


.filesList .file>svg {
    font-size: 35px;
    text-align: center;
    position: relative;
    top: calc(50% - 15px);
    color: #b5a9a9;

}

.filesList .file .buttom-close {
    margin: 0;
    padding: 0;
    width: 25px !important;
    height: 25px !important;
    color: white;
    border: none;
    background: #dc3545 !important;
    border-radius: 100%;

    position: absolute;
    right: -8px;
    top: -8px;
    /*     top: -10px: */

}

.filesList .file .buttom-close svg {
    font-size: 18px;
    position: relative;
    top: 2px;
}

/*
	CSS PARA LOS ESTILOS DEL CARD CON LA INFORMACION DEL CLIENTE
*/
.card-financiero .text-label{
	color: #A4A4A4 !important;
	font-weight: 400;
	font-size: 18px;
	margin-bottom: 0.5rem;
}

.card-cliente .text-info{
	color: #000000 !important;
	font-weight: 500;
	font-size: 15px;
}

.card-financiero .text-green{
	color: #52C41A !important;
	font-weight: 700;
	font-size: 17px;
}

.card-cliente .text-red,
.card-financiero .text-red{
	color: #FF4D4F !important;
	font-weight: 700;
	font-size: 17px;
}

.mentions-input input{
	box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  min-width: 0;
  padding: 4px 4px;
  color: #000;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #E1E4ED;
  background-image: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  transition: all 0.3s;
}

.modal-detalle .modal-col-content .ant-input.ant-input-borderless{
	border:solid 1px white !important;
}

.modal-detalle .modal-col-content .ant-select-borderless .ant-select-selector:hover,
.modal-detalle .modal-col-content .ant-input.ant-input-borderless:hover,
.modal-detalle .modal-col-content .ant-input.ant-input-borderless-focused{
	border:solid 1px #2dda93 !important;
}