.affix-multiple{
	padding: 0.5rem 2rem;
	background: linear-gradient(91.65deg, #fccb52 8.89%, #eabd4d 82.25%);
	box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.13);
	border-radius: 50px;
	transition: opacity 0.2s ease-out;
	position: fixed;
	bottom: 40px;
	opacity: 0;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	
}

.affix-multiple.show{
	
  	opacity: 1;
}

