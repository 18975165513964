.page-header{
    padding: 16px 24px;
    margin-left: 0px !important;
    margin-right: 0px !important;

    &-title, &-subtitle{
        margin: 0 !important;
        margin-bottom: 0 !important;
        color: white !important;
    }

    &-title{
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 32px !important;
    }

    &-back-icon{
        color: white;
    }
   
}