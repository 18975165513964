.message{
    .contenido{
        border: #f0f0f0 1px solid;
        width: 100%;

        .ant-card-body{
            width: 100%;
            padding: 1em;
        }

        .nombre{
            font-weight: 600;
        }

        .action{
            font-weight: 300;
            color: #8c8c8c;
        }

        .fecha {
            font-weight: 300;
            font-size: 12px;
        }
    }
}

.system{
    .contenido{
        width: 100%;

        .ant-card-body{
            width: 100%;
            padding: 0;
        }

        .nombre{
            font-weight: 600;
        }

        .action{
            font-weight: 300;
            color: #8c8c8c;
        }

        .fecha {
            font-weight: 300;
            font-size: 12px;
        }
    }
}